/* Variables de color */
:root {
    --primary-color:    #000000c4;
    --secondary-color: #0d0d0e;
    --text-color: #000000;
    --background-color: #ffffff;
}

/* Estilos generales */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

h1, h2, h3 {
    color: var(--primary-color);
}

/* Estilos para la sección del banner */
.banner_section {
    background: linear-gradient(110deg, #00557c 55%, rgba(60, 90, 90, 0) 55%), url(../images/bg1.png);
    background-repeat: no-repeat;
    background-size: cover; /* Asegura que la imagen cubra toda la sección */
    padding: 90px 0;
    position: relative;
}

.banner_taital {
    color: #ffffff;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.banner_text {
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

/* Estilos para el botón */
.see_bt a {
    background-color: #df9911;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 40px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.see_bt a:hover {
    background-color: #ffffff;
    color: #000;
}



/* ---------------------------------- */

.navbar {
    transition: background-color 0.3s, color 0.3s;
}

.navbar.bg-transparent {
    background-color: transparent;
    color: white;
}
