.cajita {
    background-color : #ffffff; /* Fondo blanco */
    color: #343a40; /* Texto oscuro */
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
}

.cajita:hover {
    transform: translateY(-10px) scale(1.1);
    background-color: #343a40; /* Fondo oscuro */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #ffffff; /* Texto blanco */
}

.cajita:hover i {
    color: #ffffff; /* Color del icono */
}